import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { PhotoList } from '../Photos/PhotoList';


// const useStyles = makeStyles((theme) => ({

// }));

export const Gifs = () => {
  // const classes = useStyles();
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    // const creative = creativePhotos.filter(photo => photo.src.includes('video'));
    // setPhotos(creative);
    fetch("https://gifs-api.azurewebsites.net/api/gifs")
      .then(res => res.json())
      .then(
        (result) => {
          // this.setState({
          //   isLoaded: true,
          //   items: result.items
          // });
          // console.log(result);
          console.log("from gif db");
          const creative = result.filter(photo => photo.src.includes('video'));
          setPhotos(creative);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // this.setState({
          //   isLoaded: true,
          //   error
          // });
          console.log(error);
        }
      )
  }, [])


  return (<PhotoList  data={photos} />)
}