import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ImageIcon from '@material-ui/icons/Image';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    width: "100%",
    height: "30px",
    // paddingTop: "25px",
    paddingBottom: "1px"
  },
  searchBar: {
    paddingTop: "13px",
    width: "100%",
    marginBottom: "14px"
    // borderBottomColor: "solid 1px black"
    // borderTop: "solid 1px black",
    // borderBottom: "solid 1px black",
    // paddingLeft: "10px",
    // paddingRight: "30px"
  },
  right: {
    float: "right",
    paddingRight: "20px"
  },
  input: {
    color: "gray",
    paddingBottom: "5px"
  },
  searchIcon: {
    paddingLeft: "20px"
  }
}));
export const SearchBar = (props) => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div>

      <div className={classes.searchBar}>
        <span className={classes.searchIcon}><SearchIcon /></span>
        <span className={classes.input}>
          <span style={{ color: "gray", textDecoration: "none" }} onClick={() => {
            if (!props.cancelButton) {
              history.push('/search')
            }
          }}> Search all the GIFs and Stickers </span>
        </span>
        {/* <span className={classes.right}> <CameraAltIcon /> </span> */}
        { props.cancelButton && <span className={classes.right} onClick={() => { history.push('/')}}>Cancel</span>}
      </div>
    </div>
  )
}