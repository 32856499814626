import React from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Header } from './Components/Header';
import { PhotoList } from './Pages/Photos/PhotoList';
import { PhotoDetail } from './Pages/Photos/PhotoDetail';
import { Creative } from './Pages/Creative';
import { Editorial } from './Pages/Editorial';
import { Gifs } from './Pages/gifs';
import { ShoppingCart } from './Pages/ShoppingCart';
import { Search } from './Pages/Search';
import { creativePhotos } from './mock.js';
const isolatePaths = ['/search']

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  searchBar: {
    paddingTop: "60px"
  },

}));


function App() {
  const classes = useStyles();

  return (
    <Router>
      <Route
        path="/"
        render={(props) => {
          return (
            !isolatePaths.includes(props.location.pathname.toLowerCase()) &&
            (<Header />)
          )
        }}
      />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Gifs />}
        />
        <Route
          exact
          path={"/photo/:photoId"}
          render={(props) => {
            return (
              <PhotoDetail
                {...props}
              />
            )
          }}
        />
        <Route
          exact
          path="/editorial"
          render={(props) => <Editorial />}
        />
        <Route
          exact
          path="/search"
          render={(props) => {
            return (
              <Search />
            )
          }}
        />
       <Route
          exact
          path="/video"
          render={(props) => {
            const video = creativePhotos.filter(photo =>  photo.src.includes('video'));
            return (
              <Gifs />
            )
          }}
        />
        <Route
          exact
          path="/shopping-cart"
          render={(props) => {
            return (
              <ShoppingCart />
            )
          }}
        />
        <Route
          exact
          path="/music"
          render={(props) => {
            return (
              <div style={{paddingTop: "80px", paddingLeft: "60px"}}>music page underconstruction</div>
            )
          }}
        />
      </Switch>
    </Router>
  );
}

export default App;
