import React from "react"
import { makeStyles } from '@material-ui/core/styles';

import { SearchBar } from '../../../Components/SearchBar';
import { CategoryMenu } from '../../../Components/CategoryMenu';
import { Photos } from '../../../Components/Photos';
import { Banner } from '../../../Components/Banner';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  searchBar: {
    paddingTop: "60px"
  },

}));

export const PhotoList = (props) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.searchBar}>
        <SearchBar />
      </div>
      <Banner />
      <div className={classes.body}>
        {/* <CategoryMenu /> */}
      </div>
      <Photos {...props} />
    </div>
  )
}
